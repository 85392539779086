import React from "react"
import Layout from '../../components/templates/Layout';
import {StaticQuery, graphql} from "gatsby";
import Header from "../../components/atoms/header/header";
import BlogListWithData from "../../components/organisms/BlogList/BlogList.query";

const BlogPageEN = () => (
    <StaticQuery query={graphql`
    {
        drupal {
            page:entityById(id: "45", langcode: EN, entityType: NODE) {
                ... on Drupal_NodePage {
                    title
                    fieldOpening 
                    body 
                    url {
                        ... on Drupal_EntityUrl {
                            metatags {
                                tag
                                attributes {
                                    key
                                    value
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    `}
     render={data => (
         <Layout
             lang={'en'}
             class={'blog'}
             activeMenu={'blog'}
             activeNid={45}
             metatags={data.drupal.page.url.metatags}
             title={data.drupal.page.title}
         >
             <Header
                 children={data.drupal.page.body}
                 intro={data.drupal.page.fieldOpening}
                 page_title={data.drupal.page.title}
             />
             <BlogListWithData />
         </Layout>
         )}
     />
);
export default BlogPageEN;
